export const fieldGallerybio = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686040542/Circular%20website/fieldtrip%20gallery/events%20gallery/fba1_gripyx.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686040548/Circular%20website/fieldtrip%20gallery/events%20gallery/fba2_qf8qao.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686040548/Circular%20website/fieldtrip%20gallery/events%20gallery/fba2_qf8qao.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686040538/Circular%20website/fieldtrip%20gallery/events%20gallery/fba3_pedi0u.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686040538/Circular%20website/fieldtrip%20gallery/events%20gallery/fba3_pedi0u.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686040542/Circular%20website/fieldtrip%20gallery/events%20gallery/fba1_gripyx.jpg",
    },
    
  ];