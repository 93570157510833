export const fieldGallery = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034939/Circular%20website/fieldtrip%20gallery/fi1_qticjz.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034930/Circular%20website/fieldtrip%20gallery/fi2_wjhn9s.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034930/Circular%20website/fieldtrip%20gallery/fi2_wjhn9s.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034941/Circular%20website/fieldtrip%20gallery/fi3_oh8puy.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034941/Circular%20website/fieldtrip%20gallery/fi3_oh8puy.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034943/Circular%20website/fieldtrip%20gallery/fi4_xauglk.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034943/Circular%20website/fieldtrip%20gallery/fi4_xauglk.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034930/Circular%20website/fieldtrip%20gallery/fi5_ajyrg8.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034930/Circular%20website/fieldtrip%20gallery/fi5_ajyrg8.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034930/Circular%20website/fieldtrip%20gallery/fi6_nafnc4.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034930/Circular%20website/fieldtrip%20gallery/fi6_nafnc4.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034934/Circular%20website/fieldtrip%20gallery/fi7_plekaf.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034934/Circular%20website/fieldtrip%20gallery/fi7_plekaf.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034934/Circular%20website/fieldtrip%20gallery/fi8_fvr3zt.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034934/Circular%20website/fieldtrip%20gallery/fi8_fvr3zt.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034936/Circular%20website/fieldtrip%20gallery/fi9_nt5fsu.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034936/Circular%20website/fieldtrip%20gallery/fi9_nt5fsu.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034940/Circular%20website/fieldtrip%20gallery/fi10_m3wv7d.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034940/Circular%20website/fieldtrip%20gallery/fi10_m3wv7d.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034936/Circular%20website/fieldtrip%20gallery/fi11_v8z0w1.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034936/Circular%20website/fieldtrip%20gallery/fi11_v8z0w1.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034938/Circular%20website/fieldtrip%20gallery/fi12_aksvzw.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034938/Circular%20website/fieldtrip%20gallery/fi12_aksvzw.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686034939/Circular%20website/fieldtrip%20gallery/fi1_qticjz.jpg",
    },
    
    
  ];