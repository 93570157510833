export const descGallery = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di1_apd9o2.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di2_eqacup.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di2_eqacup.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di3_eolpq9.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di3_eolpq9.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di4_kwptkf.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di4_kwptkf.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di5_aoncqm.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di5_aoncqm.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di6_sl6fsp.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di6_sl6fsp.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849698/Circular%20website/desc%20gallery/di7_vfwplq.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849698/Circular%20website/desc%20gallery/di7_vfwplq.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849697/Circular%20website/desc%20gallery/di1_apd9o2.jpg",
    },

    
  ];