export const fieldGalleryscl = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849648/Circular%20website/aboutus%20gallery/ig1_cjm0yk.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849648/Circular%20website/aboutus%20gallery/ig7_aelh13.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849648/Circular%20website/aboutus%20gallery/ig7_aelh13.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849650/Circular%20website/aboutus%20gallery/ig6_gspmoq.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849650/Circular%20website/aboutus%20gallery/ig6_gspmoq.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849653/Circular%20website/aboutus%20gallery/ig5_bkrfxr.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849653/Circular%20website/aboutus%20gallery/ig5_bkrfxr.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849651/Circular%20website/aboutus%20gallery/ig8_zt2jni.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849651/Circular%20website/aboutus%20gallery/ig8_zt2jni.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849653/Circular%20website/aboutus%20gallery/ig9_xrv17r.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849653/Circular%20website/aboutus%20gallery/ig9_xrv17r.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849654/Circular%20website/aboutus%20gallery/ig3_zkccvm.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849654/Circular%20website/aboutus%20gallery/ig3_zkccvm.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849657/Circular%20website/aboutus%20gallery/ig4_m6htsr.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849657/Circular%20website/aboutus%20gallery/ig4_m6htsr.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849648/Circular%20website/aboutus%20gallery/ig1_cjm0yk.jpg",
    },
  ];