export const fieldGallerypharma = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043958/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp1_rebsmp.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043944/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp2_aaa5us.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043944/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp2_aaa5us.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043955/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp3_sczdaw.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043955/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp3_sczdaw.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043955/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp4_bsitqx.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043955/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp4_bsitqx.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043954/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp5_jdek2h.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043954/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp5_jdek2h.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043953/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp6_v24kbd.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043953/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp6_v24kbd.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043946/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp7_wxkvy3.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043946/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp7_wxkvy3.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043947/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp8_rxztzm.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043947/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp8_rxztzm.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043949/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp9_vioari.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043949/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp9_vioari.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043951/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp10_iu5hoq.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043951/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp10_iu5hoq.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043953/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp11_zft0pn.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043953/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp11_zft0pn.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043955/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp12_wwrt6g.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043955/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp12_wwrt6g.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043957/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp13_zpc4bu.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043957/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp13_zpc4bu.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043957/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp14_c2khzf.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043957/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp14_c2khzf.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043957/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp15_xsvlkt.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043957/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp15_xsvlkt.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043961/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp16_v81jfp.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043961/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp16_v81jfp.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043961/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp17_wkis5y.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043961/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp17_wkis5y.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043960/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp18_pyxgyq.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043960/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp18_pyxgyq.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686043958/Circular%20website/fieldtrip%20gallery/events%20gallery/fieldgallerypharma%20fp/fp1_rebsmp.jpg",
    },
    
    
  ];