export const hospitalityGallery2 = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849760/Circular%20website/hospitality%20galley/hi7_ipyas8.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849760/Circular%20website/hospitality%20galley/hi8_qki83v.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849760/Circular%20website/hospitality%20galley/hi8_qki83v.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849762/Circular%20website/hospitality%20galley/hi9_gho0bf.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849762/Circular%20website/hospitality%20galley/hi9_gho0bf.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849761/Circular%20website/hospitality%20galley/hi10_suxv2e.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849761/Circular%20website/hospitality%20galley/hi10_suxv2e.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849760/Circular%20website/hospitality%20galley/hi11_swdub0.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849760/Circular%20website/hospitality%20galley/hi11_swdub0.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849761/Circular%20website/hospitality%20galley/hi12_nnducp.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849761/Circular%20website/hospitality%20galley/hi12_nnducp.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849762/Circular%20website/hospitality%20galley/hi13_z3yfff.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849762/Circular%20website/hospitality%20galley/hi13_z3yfff.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849760/Circular%20website/hospitality%20galley/hi7_ipyas8.jpg",
    },

    
  ];