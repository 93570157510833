export const fieldGalleryid = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686041182/Circular%20website/fieldtrip%20gallery/events%20gallery/fid1_zenslm.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686041201/Circular%20website/fieldtrip%20gallery/events%20gallery/fid2_z9kkep.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686041201/Circular%20website/fieldtrip%20gallery/events%20gallery/fid2_z9kkep.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686041182/Circular%20website/fieldtrip%20gallery/events%20gallery/fid3_pgyskg.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686041182/Circular%20website/fieldtrip%20gallery/events%20gallery/fid3_pgyskg.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686041183/Circular%20website/fieldtrip%20gallery/events%20gallery/fid4_gwvcja.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686041183/Circular%20website/fieldtrip%20gallery/events%20gallery/fid4_gwvcja.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1686041182/Circular%20website/fieldtrip%20gallery/events%20gallery/fid1_zenslm.jpg",
    },    
    
  ];