export const smaeGallery = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856391/Circular%20website/smae%20gallery/si1_bn3djp.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si2_fb0mur.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si2_fb0mur.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si3_by7ly9.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si3_by7ly9.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si4_vn5pwm.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si4_vn5pwm.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si5_laek1s.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si5_laek1s.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si6_dtkmzy.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si6_dtkmzy.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856391/Circular%20website/smae%20gallery/si7_dvecte.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856391/Circular%20website/smae%20gallery/si7_dvecte.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si8_xndobw.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856389/Circular%20website/smae%20gallery/si8_xndobw.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856392/Circular%20website/smae%20gallery/si9_peyf3c.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856392/Circular%20website/smae%20gallery/si9_peyf3c.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856390/Circular%20website/smae%20gallery/si10_e9rvpn.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856390/Circular%20website/smae%20gallery/si10_e9rvpn.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685856391/Circular%20website/smae%20gallery/si1_bn3djp.jpg",
    },
  
  ];