export const hospitalityGallery = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi1_vrecqy.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi2_ltbopt.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi2_ltbopt.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi3_vpynb5.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi3_vpynb5.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi4_m9y2l2.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi4_m9y2l2.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi5_v5kg0b.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi5_v5kg0b.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi6_gymoa8.jpg",
    },
    {
        original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi6_gymoa8.jpg",
        thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1685849759/Circular%20website/hospitality%20galley/hi1_vrecqy.jpg",
    },

    
  ];