export const rukhminiGallery = [
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1687197024/Circular%20website/Dr.%20J.%20Rukmini/WhatsApp_Image_2023-06-19_at_21.39.59_lwutk5.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1687197026/Circular%20website/Dr.%20J.%20Rukmini/WhatsApp_Image_2023-06-19_at_21.40.00_jtpksk.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1687197026/Circular%20website/Dr.%20J.%20Rukmini/WhatsApp_Image_2023-06-19_at_21.40.00_jtpksk.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1687197026/Circular%20website/Dr.%20J.%20Rukmini/WhatsApp_Image_2023-06-19_at_21.40.00_1_j3jyxp.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1687197026/Circular%20website/Dr.%20J.%20Rukmini/WhatsApp_Image_2023-06-19_at_21.40.00_1_j3jyxp.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1687197024/Circular%20website/Dr.%20J.%20Rukmini/WhatsApp_Image_2023-06-19_at_21.40.00_2_upxrim.jpg",
    },
    {
      original: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1687197024/Circular%20website/Dr.%20J.%20Rukmini/WhatsApp_Image_2023-06-19_at_21.40.00_2_upxrim.jpg",
      thumbnail: "https://res.cloudinary.com/dh4bpcja3/image/upload/v1687197024/Circular%20website/Dr.%20J.%20Rukmini/WhatsApp_Image_2023-06-19_at_21.39.59_lwutk5.jpg",
    }
  ];